import React, { useRef, useEffect, useState } from 'react';
import {BrowserRouter, Link } from "react-router-dom";
// import Modal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { BigPlayButton, ControlBar, Player } from 'video-react';
// import { openPopupWidget } from 'react-calendly';
import CalendlyEmbed from './CalendlyEmbedReact.js';
// import Counter from 'react-number-counter'
import './App.css';
import './AppResponsive.css';


var trusted = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 992,
      settings: { 
        slidesToShow: 4, 
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 1199,
      settings: { 
        slidesToShow: 5, 
        slidesToScroll: 1
      },
    },
  ],
};

var projectGrid = {
  dots: false,
  infinite: true,
  speed: 1000,
  // rtl: true,
  autoplaySpeed: 2000,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  pauseOnHover:false,
  nav: false,
  responsive: [
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 992,
      settings: { 
        slidesToShow: 4, 
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 1199,
      settings: { 
        slidesToShow: 5, 
        slidesToScroll: 1
      },
    },
  ],
};

var projectGrid2 = {
  dots: true,
  margin: 40,
  infinite: true,
  speed: 1000,
  // rtl: true,
  autoplaySpeed: 2000,
  autoplay: false,
  pauseOnHover:false,
  nav: false,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};


function App() {
  const commentSection = useRef(null);
  const gotoCommentSection = () =>
    window.scrollTo({
      top: commentSection.current.offsetTop - 100,
      behavior: 'smooth',
    });
  
    const customersSection = useRef(null);
    const gotoCustomersSection = () =>
      window.scrollTo({
        top: customersSection.current.offsetTop - 100,
        behavior: 'smooth',
    });
    
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
      });
    }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
      <header className={scroll ? "fixed" : ""}>
        <div className="container">
          <div className="header-inner">
            <div className="logo">
              <Link to="/"><img src="/img/logo-black.svg" alt="Logo"/></Link>
            </div>
            <div className="navigation">
              <ul>
                {/* <li><Link to="/"><span><img src="/img/call.svg" alt="call"/></span>Call us now  9810217917</Link></li> */}
                <li><Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free Strategy call</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <img src="/img/banner-img.svg" className="banner-img" alt="" />

      <section className="banner-section">
        <div className="container">
          <h6>Join the top 5% of Ecom Stores who </h6>
          <h2>Convert 2X more customers <span>while decreasing the AD spent</span></h2>
          <p>with Flying Saints 360 degree conversion optimization hacks</p>
          <Link to="/" className="main-btn">Request a Call</Link>
          <div className="banner-img-grid">
            <img src="/img/banner-img2.svg" alt="" />
            <img src="/img/banner-icon1.svg" className="banner-icon1" alt="" />
            <img src="/img/banner-icon2.svg" className="banner-icon2" alt="" />
            <img src="/img/banner-icon3.svg" className="banner-icon3" alt="" />
            <img src="/img/banner-icon4.svg" className="banner-icon4" alt="" />
          </div>
        </div>
      </section>

      <section className="trusted-section">
        <div className="container">
            <Slider {...trusted}>
              <div className="trustedImg">
                <img src="img/trusted1.png" alt=""/>
              </div>
              <div className="trustedImg">
                <img src="img/trusted2.png" alt=""/>
              </div>
              <div className="trustedImg">
                <img src="img/trusted3.png" alt=""/>
              </div>
              <div className="trustedImg">
                <img src="img/trusted4.png" alt=""/>
              </div>
              <div className="trustedImg">
                <img src="img/trusted5.png" alt=""/>
              </div>
              <div className="trustedImg">
                <img src="img/trusted6.png" alt=""/>
              </div>
            </Slider>
        </div>
      </section>

      <section className="join-section">
        <div className="container">
          <h3>Join the top 5% of Ecommerce Stores <span>who convert users into customers and sell like</span> crazy on their ecom stores</h3>
          <p>Have you burnt your fingers? You might have worked with an e-commerce web design company. Maybe a couple. but still No results. You were sold on promises by agencies but those promises never delivered. and you are disappointed with your existing online store because it just doesn’t sell. 
          <span>When you started you believed that your online store would become a great source of income. Instead, it has turned into a drain that is constantly swallowing up your money and you feel today that</span>
          <span><b>Advertising doesn’t work. Marketing doesn’t work. Organic search traffic isn’t working.</b></span>
          <span>People come to your website, but they don’t buy. Or very few buy. You know your product is great and there is great demand for what you’re offering.</span>
          <span>Then what’s wrong?  <b>It’s the UX.</b></span>
          <span>When the visitors don’t have a good experience on your online store, they leave without buying. User experience is about the visual appeal as well as the ease of use. Your design must be functional and  accessible to all types of users.</span>
          <span>Only an experienced e-commerce design service understands the finer details that have a big impact on your bottom line and <b>we can help you create one.</b></span></p>
          <Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free Strategy call</Link>
        </div>
      </section>

      <section className="projectGridSection">
          <div className="projectGridInner">
            <Slider {...projectGrid}>
              <div className="projectGrid">
                <img src="img/projectGrid1.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid2.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid3.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid4.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid5.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid6.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid7.png" alt=""/>
              </div>
              <div className="projectGrid">
                <img src="img/projectGrid8.png" alt=""/>
              </div>
            </Slider>
          </div>
      </section>

      <section className="bankrupt-section">
        <div className="container">
          <div className="bankrupt-inner">
            <div className="bankrupt-content">
              <h3>Your Ecom Store should make you money, not wasting all your money on ADs and making you Bankrupt.</h3>
              <p>You website should leverage all the marketing and should  convert like crazy . It should talk to the users showcasing why your product is the right choice for them. <span><b>At Flying Saints</b>, We Keep your goal in mind and design a website that stands out from industry competition and convert more visitors into customers.</span></p>
              <Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free consultation call today</Link>
            </div>
            <div className="bankrupt-img">
              <img src="/img/bankrupt.svg" alt=""/>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bankrupt-section design-section">
        <div className="container">
          <div className="bankrupt-inner">
            <div className="bankrupt-img">
              <img src="/img/design.png" alt=""/>
            </div>
            <div className="bankrupt-content">
              <h3>Shopify design & Build</h3>
              <p>Starting form</p>
              <h5>$2700</h5>
              <Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free consultation</Link>
              <b>Design, Store setup and development</b>
              <ul>
                <li>Product management</li>
                <li>Logistics Management</li>
                <li>Multiple supplier API integrations</li>
                <li>Dynamic inventory & pricing</li>
                <li>Dropshipping management</li>
                <li>Inventory Management</li>
                <li>Multiple supplier API integrations</li>
                <li>Complex de-duplication and aggregation algorithms</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bankrupt-section">
        <div className="container">
          <div className="bankrupt-inner">
            <div className="bankrupt-content">
              <h3>Hi , I’m Gurpreet bhatia</h3>
              <p><b>I am a results-oriented user experience designer focussed on delivering massive ROI.</b><span>You website should leverage all the marketing and should  convert like crazy . It should talk to the users showcasing why your product is the right choice for them.<br/> HFI-Certified Usability Expert running his own UX design studio called Flying Saints.</span> <span><b>At Flying Saints,</b> We Keep your goal in mind and design a website that stands out from industry competition and convert more visitors into customers.</span></p>
              <Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free consultation call today</Link>
            </div>
            <div className="bankrupt-img">
              <img src="/img/profile.png" alt=""/>
            </div>
          </div>
        </div>
      </section> */}

      <div className="container">
         <div className="tool-inner">
            <h6>Cross platform itegration</h6>
            <h3>Deeply integrated with your favourite tool</h3>
            <ul>
              <li><img src="/img/tool1.jpg" alt=""/></li>
              <li><img src="/img/tool2.jpg" alt=""/></li>
              <li><img src="/img/tool3.jpg" alt=""/></li>
              <li><img src="/img/tool4.jpg" alt=""/></li>
              <li><img src="/img/tool5.jpg" alt=""/></li>
              <li><img src="/img/tool6.jpg" alt=""/></li>
              <li><img src="/img/tool7.jpg" alt=""/></li>
              <li><img src="/img/tool8.jpg" alt=""/></li>
              <li><img src="/img/tool9.jpg" alt=""/></li>
              <li><img src="/img/tool10.jpg" alt=""/></li>
              <li><img src="/img/tool11.jpg" alt=""/></li>
              <li><img src="/img/tool12.jpg" alt=""/></li>
              <li><img src="/img/tool13.jpg" alt=""/></li>
              <li><img src="/img/tool14.jpg" alt=""/></li>
              <li><img src="/img/tool15.jpg" alt=""/></li>
              <li><img src="/img/tool16.jpg" alt=""/></li>
              <li><img src="/img/tool17.jpg" alt=""/></li>
              <li><img src="/img/tool18.jpg" alt=""/></li>
              <li><img src="/img/tool19.jpg" alt=""/></li>
              <li><img src="/img/tool20.jpg" alt=""/></li>
              <li><img src="/img/tool21.jpg" alt=""/></li>
              <li><img src="/img/tool22.jpg" alt=""/></li>
              <li><img src="/img/tool23.jpg" alt=""/></li>
              <li><img src="/img/tool7.jpg" alt=""/></li>
            </ul>
          </div>
        </div>

      <section className="project-section">
        <Slider {...projectGrid2}>
          <div className="project-inner">
              <div className="project-inner-main">
                <div className="project-inner-img">
                  <img src="img/vestige.png" alt=""/>
                  {/* <img src="img/vestige2.png" className="mobile-img" alt=""/> */}
                </div>
                <div className="project-content">
                  <div className="project-left">
                  <h3>Vestige increased its AOV by cross-selling on cart</h3>
                  <p>Vestige, India's largest direct marketing company was struggling with bad conversion rate on the ecommerce website and was loosing a ton of money and customers because of bad user experience on website but after understanding the business needs and user needs we implemented new customer journeys and interface which will raise the engagement time and sale by 4x therefore they are looking to sell more and convert more from theor ecommerce website</p>
                </div>
                
                  <div className="project-right">
                  <ul>
                    <li><span>25%</span> <b>Incraese in  <i>basket building</i></b></li>
                    <li><span>40%</span> <b>Increased in <i>Average order value</i></b></li>
                    <li><span>12%</span> <b>Increase from  <i>a intelligent chatbot</i></b></li>
                  </ul>
                </div>
                </div>
              </div>
          </div>

          <div className="project-inner">
              <div className="project-inner-main">
                <div className="project-inner-img">
                  <img src="img/nirvana.png" alt=""/>
                  {/* <img src="img/nirvana2.png" className="mobile-img" alt=""/> */}
                </div>
                <div className="project-content">
                  <div className="project-left">
                    <h3>Nirvana being got instant ROI through store redesign</h3>
                    <p>Nirvana Being, The air purification company was looking to overhaul their website store design and was afraid to loose current customers because of a new fresh look but at flying saints we implemented new store design and therefore they got instant return on investment and added 15 new customers everymonth</p>
                  </div>
                
                  <div className="project-right">
                    <ul>
                      <li><span>245%</span> <b>Avg. YOY increased <i>Revenue</i></b></li>
                      <li><span>$3</span> <b>from every $1 <i>spent on Ads</i></b></li>
                      <li><span>34%</span> <b>revenue increased  <i>from a chatbot</i></b></li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>

          <div className="project-inner">
              <div className="project-inner-main">
                <div className="project-inner-img">
                  <img src="img/only.png"  alt=""/>
                  {/* <img src="img/only2.png" className="mobile-img" alt=""/> */}
                </div>
                <div className="project-content">
                  <div className="project-left">
                    <h3>Only natural floats above the competition</h3>
                    <p>Only Natural, The online Honey giant was unable to generate sales and their e-commerce venture was just sitting idle but after we implemented a new interface and conversion startegies, Their conversion rose to 400% with a decreased ad spent and therefore <b>they now spending less money on the ads and selling more honey than before</b></p>
                  </div>
                
                  <div className="project-right">
                    <ul>
                      <li><span>5.7%</span> <b>Increased revenue <i>by Cross selling</i></b></li>
                      <li><span>250%</span> <b>Revenue <i>increased</i></b></li>
                      <li><span>16%</span> <b>Incraese Revenue <i>from search </i></b></li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>

          <div className="project-inner">
              <div className="project-inner-main">
                <div className="project-inner-img">
                  <img src="img/nirogam.png" alt=""/>
                  {/* <img src="img/nirogam2.png" className="mobile-img" alt=""/> */}
                </div>
                <div className="project-content">
                  <div className="project-left">
                    <h3>Nirogam.com increased checkouts by 13.9%</h3>
                    <p>Indias largest Ayurvedic online store despite having brilliant products was struggling to convert users and wanted to change the interface of the website but the problem was the experience on mobile, as 80% of his users are on small screen devices therefore, after implementing the Flying saints C2C Mobile framework which is focussed on mobile experience <b>Today they are converting twice the number of users from his earlier website</b></p>
                  </div>
                
                  <div className="project-right">
                    <ul>
                      <li><span>162%</span> <b>Online sales growth <i>*7 figures in  monthly sales</i></b></li>
                      <li><span>3x</span> <b>Increase in  <i>Mobile Conversion</i></b></li>
                      <li><span>10%+</span> <b>Ecommerce  <i>Conversion Rate</i></b></li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </Slider>
      </section>

      <section className="team-section">
        <div className="team-img">
          <img src="/img/team1.png" className="desktop-img" alt=""/>
          <img src="/img/team1_1.jpg" className="mobile-img" alt=""/>
        </div>
        <div className="team-img">
          <img src="/img/team2.png" className="desktop-img" alt=""/>
          <img src="/img/team2_1.jpg" className="mobile-img" alt=""/>
        </div>
        <div className="team-img">
          <img src="/img/team3.png" className="desktop-img" alt=""/>
          <img src="/img/team3_1.jpg" className="mobile-img" alt=""/>
        </div>
        <div className="team-img">
          <img src="/img/team4.png" className="desktop-img" alt=""/>
          <img src="/img/team4_1.jpg" className="mobile-img" alt=""/>
        </div>
      </section>


      <section className="process-section">
        <div className="container">
          <div className="process-inner">
            <h2><span>The Secret Ingredient is </span> The process <img src="img/flying-icon.png" alt="" /></h2>
            <div className="achieved">
              <h4>To achieve great results, we follow a process used by the biggest corporations in the world including </h4>
              <ul>
                <li><img src="img/process1.png" alt=""/></li>
                <li><img src="img/process2.png" alt=""/></li>
                <li><img src="img/process3.png" alt=""/></li>
                <li><img src="img/process4.png" alt=""/></li>
                <li><img src="img/process5.png" alt=""/></li>
                <li><img src="img/process6.png" alt=""/></li>
                <li><img src="img/process7.png" alt=""/></li>
                <li><img src="img/process8.png" alt=""/></li>
                <li><img src="img/process9.png" alt=""/></li>
                <li><img src="img/process10.png" alt=""/></li>
              </ul>
            </div>
            <div className="sprint">
              <h3>It’s called “Design Sprint”.</h3>
              <p>At the center of this process are your target customers. This is an intensive undertaking. The entire team holds marathon sessions. A small group of test users is created. The design ideas and prototypes are created after obtaining insights and feedback from this test user group. Multiple iterations are done until the best possible solution is obtained. <span>We don’t create e-commerce designs in the vacuum. We don’t believe in throwing darts in the darkness. We deliver solid results backed by dependable insights.</span></p>
              <Link to="/" className="main-btn" onClick={gotoCommentSection}>Yes ! I want to book a strategy call <span>And want to know more about design sprint</span></Link>
            </div>
            <img src="/img/sprint-left.png" className="sprint-left" alt=""/>
            <img src="/img/sprint-right.png" className="sprint-right" alt=""/>
          </div>
        </div>
      </section>
      
      <section className="customer-section" ref={customersSection}>
        <div className="container">
          <h3>What Customers Say about us</h3>
          <p>Our happy customers has something to say for our work</p>
          <div className="customer-inner">
            <div className="customer-card">
              <div className="customer-profile">
                <div className="customer-img">
                  <img src="/img/customer1.jpg" alt="" />
                </div>
                <div className="customer-content">
                  <h6>David Park</h6>
                  <p>A week ago</p>
                  <Link to="/"><img src="/img/google.svg" alt="" /></Link>
                </div>
              </div>
              <ul>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
              </ul>
              <p>we’re able to attract and convert more customers. They’re conversion rockstars</p>
              <span><i class="fa fa-thumbs-up"></i> Like</span>
            </div>
            <div className="customer-card">
              <div className="customer-profile">
                <div className="customer-img">
                  <img src="/img/customer2.jpg" alt="" />
                </div>
                <div className="customer-content">
                  <h6>Ben Bounketh </h6>
                  <p>A week ago</p>
                  <Link to="/"><img src="/img/google.svg" alt="" /></Link>
                </div>
              </div>
              <ul>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
              </ul>
              <p>The team communicates quickly and gets things done as promised !!</p>
              <span><i class="fa fa-thumbs-up"></i> Like</span>
            </div>
            <div className="customer-card">
              <div className="customer-profile">
                <div className="customer-img">
                  <img src="/img/customer3.jpg" alt="" />
                </div>
                <div className="customer-content">
                  <h6>Margot whitney</h6>
                  <p>A week ago</p>
                  <Link to="/"><img src="/img/google.svg" alt="" /></Link>
                </div>
              </div>
              <ul>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
              </ul>
              <p>Can’t thank them enough for our new store that too in just two weeks.</p>
              <span><i class="fa fa-thumbs-up"></i> Like</span>
            </div>
            <div className="customer-card">
              <div className="customer-profile">
                <div className="customer-img">
                  <img src="/img/customer4.jpg" alt="" />
                </div>
                <div className="customer-content">
                  <h6>Emil Ray</h6>
                  <p>A week ago</p>
                  <Link to="/"><img src="/img/google.svg" alt="" /></Link>
                </div>
              </div>
              <ul>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
              </ul>
              <p>we are now able to close 4 times more customers than our previous website</p>
              <span><i class="fa fa-thumbs-up"></i> Like</span>
            </div>
            <div className="customer-card">
              <div className="customer-profile">
                <div className="customer-img">
                  <img src="/img/customer5.jpg" alt="" />
                </div>
                <div className="customer-content">
                  <h6>Jeff walden</h6>
                  <p>A week ago</p>
                  <Link to="/"><img src="/img/google.svg" alt="" /></Link>
                </div>
              </div>
              <ul>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
              </ul>
              <p>We reached 50 orders a day and growing in just 3 months.</p>
              <span><i class="fa fa-thumbs-up"></i> Like</span>
            </div>
            <div className="customer-card">
              <div className="customer-profile">
                <div className="customer-img">
                  <img src="/img/customer6.jpg" alt="" />
                </div>
                <div className="customer-content">
                  <h6>Mackinsey theodre</h6>
                  <p>A week ago</p>
                  <Link to="/"><img src="/img/google.svg" alt="" /></Link>
                </div>
              </div>
              <ul>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
                <li><i className="fa fa-star"></i></li>
              </ul>
              <p>UX expertise, they bring to the table is unmatched. We convert like crazy</p>
              <span><i class="fa fa-thumbs-up"></i> Like</span>
            </div>
          </div>
        </div>
      </section>

      <section className="advance-section">
        <div className="container">
          <h6>We've Thought everything in advance for you</h6>
          <h3>Get A Website That has everything you <span>wished & more</span></h3>
          <p>Your ecom Store will have all the Awesomeness and much more than your competion website</p>
          <div className="advance-inner">
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance1.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Conversion Hacks</h6>
                <p>Secrets & strategies of the world's high converting websites unwrapped for your e-commerce store.</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance2.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Mobile Responsive</h6>
                <p>Your website will automatically adapt to screen sizes on whichever device it is being viewed on.</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance3.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Optimised for SEO</h6>
                <p>Your website will build its own traffic engine from google so that you reach the right audience evrytime.</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance4.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Secured website</h6>
                <p>your website will feature Top notch security features keeping hackers and cyber-thieves miles away</p>
              </div>
            </div> 
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance5.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Performance analytics ready</h6>
                <p>Data-driven insights into how shoppers interact with your site both good and bad</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance6.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Fast checkout</h6>
                <p>Express checkout is designed specifically for returning users for a one-click checkout experience.</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance7.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Unlimited products</h6>
                <p>Launch and sell an infinite number of products and still, your website will perform faster than ever.</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance8.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Product landing pages</h6>
                <p>Forget paying Click Funnels! High converting Product landing pages are embeded in your website already</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance9.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Automation</h6>
                <p>Make your business workflow efficient and convenient. Eliminate manual data entry from day one.</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance10.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Fastest Loading speed</h6>
                <p>Time is money when it comes to ecommerce speed. Never loose a sale again because of slow website speed</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance11.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Remarketing Ready</h6>
                <p>Track your visitors after they have left your website with facebook pixels and google tags working for you 24x7x365</p>
              </div>
            </div>
            <div className="advance-card">
              <div className="advance-img">
                <img src="/img/advance12.png" alt="" />
              </div>
              <div className="advance-content">
                <h6>Chatbot Support</h6>
                <p>24x7 customer assistance  by chatbots dramatically reduce human error and give efficient customer service</p>
              </div>
            </div>
          
          
          </div>
        </div>
      </section>
      
      
      <section className="high-section">
        <div className="container">
          <h3>Get a High converting website and <span>double your profit in less than 6 months</span></h3>
          <p>Jon hundreds of business owners and discover what Flying <span>Saints C2C framework can do for you today?</span></p>
          <Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free strategy call</Link>
        </div>
        <img src="/img/high-bg2.jpg" className="high-bg" alt=""/>
      </section>

      
      <section className="benefit-section">
        <div className="container">
          <h6>Our Benefits</h6>
          <h3>6 Reasons to choose Flying Saints</h3>
          <div className="benefit-inner">
            <div className="benefit-card">
              <img src="/img/benefit-icon1.svg" alt="" />
              <h6>We are Ecommerce conversion <span>experts</span></h6>
              <p>If you have to spoon-fed your last designer for the ideas then we understand what you have gone through but at Flying saints our expert team brainstorm and generate ideas which brings business to your door</p>
            </div>
            <div className="benefit-card">
              <img src="/img/benefit-icon2.svg" alt="" />
              <h6>Strategies of High Converting  <span>brands</span></h6>
              <p>We implement case studies and strategies f the top high converting websites of the world. We know what works and what will not. In the end , you have a website which is amalgamation of worlds best websites</p>
            </div>
            <div className="benefit-card">
              <img src="/img/benefit-icon3.svg" alt="" />
              <h6>Last Redesign you will ever  <span>need</span></h6>
              <p>if you are the one who has spent thousands and thousands of dollars n redesign then this is your last investment as it comes packed with all conversion hacks</p>
            </div>
            <div className="benefit-card">
              <img src="/img/benefit-icon4.svg" alt="" />
              <h6>You Track everything</h6>
              <p>from progress to timesheets to designers working on your project, you track everything with you own dashboard which updates on the task in progress, complete or pending   on the go</p>
            </div>
            <div className="benefit-card">
              <img src="/img/benefit-icon5.svg" alt="" />
              <h6>Designed for mobile</h6>
              <p>More than 75% people buy from  mobile. Keeping this in mind we have made a 21 checkpoint list for conversion for mobile which converts users from mobile like crazy</p>
            </div>
            <div className="benefit-card">
              <img src="/img/benefit-icon6.svg" alt="" />
              <h6>Fast Turnaroud</h6>
              <p>we are a team of designers, developer and digital strategists under one roof which makes it lighting fast to turnaround a project</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bankrupt-section get-section">
        <div className="container">
          <div className="bankrupt-inner">
            <div className="bankrupt-img">
              <img src="/img/get.png" alt=""/>
            </div>
            <div className="bankrupt-content">
              <h3>Get a website designed, which your customers will love to buy from</h3>
              <p>If you are not happy with your current website because it does not connect well with your users. And if you are scared to invest more money in your current websute as it will go down the drain then boost your website with 2x conversion with the Ec2c Framework. Attract more and sell more. <b>2x growth guranteed</b></p>
              <Link to="/" className="main-btn">yes! i Want 2x more cutomers</Link>
            </div>
          </div>
          
        </div>
      </section>

      
      
      <section className="high-section">
        <div className="container">
          <h3>Join 1000s of Business Owners who got a High <span>Converting Website from Flying Saints</span></h3>
          <p>Discover what Flying Saints C2C framework can do for you today?</p>
          <Link to="/" className="main-btn" onClick={gotoCommentSection}>Book a free strategy call</Link>
        </div>
        <img src="/img/high-bg2.jpg" className="high-bg" alt=""/>
      </section>

      <section className="talk-section" >
        <div className="container">
          <h6>Get your website Audit , Take The First Step Today</h6>
          <h3>Book your Free No commitment Strategy call</h3>
          <p ref={commentSection}>Talk to one of our website development specialists to discover the difference a well-designed and perfectly functioning website will make to your customer numbers and your cash flow. Use the calendar below to schedule your call today! (Don’t forget to check the timezone!)</p>

          <div className="expect-grid">
            <h6>Here's what you can expect:</h6>
            <ul>
              <li><b>10-minute dive into your business to</b> understand where you are at, where you want to go, and what's stopping you from getting there</li>
              <li><b>10 minute discussion on the top strategies</b> used by world highest converting ecommerce store and how you can use them</li>
              <li><b>10 minute audit of your mobile conversion</b> and how you can increase it two folds</li>
              <li><b>+ Bonus - A step by step PDF checklist of universal UX elements</b> for improving your mobile site experience across all areas</li>
            </ul>
          </div>
          <div className="talk-calendly">
            <CalendlyEmbed account="acmeInc" eventName="consultation" />
          </div>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="footer-inner">
            <div className="copy-right">
              <p>© 2020 Flying saints. All rights reserved</p>
               <a href="https://ecom.flyingsaints.com/privacy.html">Privacy Policy</a>
            </div>
            <div className="footer-logo">
              <Link to="/"><img src="/img/logo.svg" alt="Logo"/></Link>
            </div>
            <div className="footer-social">
              <ul>
                <li><Link to="/"><i className="fa fa-instagram"></i></Link></li>
                <li><Link to="/"><i className="fa fa-dribbble"></i></Link></li>
                <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>
                <li><Link to="/"><i className="fa fa-youtube-play"></i></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-fixed">
        <ul>
          <li><Link to="/" onClick={gotoCustomersSection}><img src="/img/skill-icon.svg" alt="Logo"/> <span>Success Stories</span></Link></li>
          <li><Link to="/" onClick={gotoCommentSection}><img src="/img/call-icon.svg" alt="Logo"/> <span>Book a Call</span></Link></li>
        </ul>
      </div>

      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
